.container-spacing--lg {
  --container-spacing-lg: 64px;

  @include for-tablet-up {
    --container-spacing-lg: 128px;
  }

  margin-bottom: var(--container-spacing-lg);
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.intercom-lightweight-app-launcher,
.intercom-launcher-frame {
  bottom: 64px !important;

  @include for-tablet-up {
    bottom: 82px !important;
  }

  @include for-tablet-large-up {
    bottom: 20px !important;
  }
}

.intercom-messenger-frame {
  bottom: 100px !important;

  @include for-tablet-up {
    bottom: 142px !important;
  }
}
