.filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;

  &__wrapper {
    max-width: 1040px;
    padding: 47px 39px;
    margin: 0 auto 24px;
    background-image: linear-gradient(
      to bottom,
      rgba(153, 163, 175, 0.3),
      rgba(255, 255, 255, 0)
    );
    box-shadow: 0 5px 19px 0 rgba(144, 142, 142, 0.5);
  }

  &__title {
    margin-bottom: 33px;
    font-size: 32px;
    font-weight: $font-medium;
    color: $blue;
    text-align: center;
    letter-spacing: 1.67px;
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }
}

.filter-mobile {
  padding: 20px 25px;
  margin-bottom: 29px;
  background-image: linear-gradient(
    to bottom,
    rgba(153, 163, 175, 0.3),
    rgba(255, 255, 255, 0)
  );
  box-shadow: 0 5px 19px 0 rgba(144, 142, 142, 0.5);

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: 32px;
    font-size: 28px;
    font-weight: 500;
    color: $blue;
    text-align: center;
    letter-spacing: 1.56px;
  }

  &__icon {
    margin-right: 10px;
  }

  &__text {
    font-weight: bold;
    color: $blue;
    letter-spacing: 0.89px;

    &__wrapper {
      display: flex;
      align-items: center;
    }
  }

  &-items {
    margin-top: 30px;
    animation: opacity-animate 0.2s linear;
  }

  &-accordion {
    padding-left: 9px;

    &__summary {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      user-select: none;
      border-bottom: 1px solid #979797;

      &__title {
        color: $blue;
        letter-spacing: 0.89px;
      }
    }

    &__details {
      padding: 11px 11px 0;
      animation: opacity-animate 0.2s linear;

      & > label {
        margin-bottom: 8px;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
  }
}

.filter-quick {
  display: flex;
  justify-content: flex-end;
  margin-right: 30px;
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: 768px) {
    margin-right: 24px;
  }

  &__wrapper {
    @media screen and (min-width: 768px) {
      max-width: 1040px;
      margin: 0 auto;
    }
  }

  &__label {
    margin-right: 8px;
    font-size: 14px;
    font-weight: bold;
    color: $blue;
    letter-spacing: 0.78px;
  }
}

.filters {
  position: relative;
  z-index: 2;
}
