.sticky-header-wrapper {
  background-color: #fff;
  border: solid 1px #979797;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.19);
  z-index: 100;
}

.sticky-header {
  --sticky-header-padding: 16px;

  @include for-tablet-up {
    --sticky-header-padding: 24px 40px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--sticky-header-padding);
  max-width: 1440px;
  margin: 0 auto;
}

.sticky-steps {
  --steps-flex-direction: column;
  --steps-align-items: flex-start;
  --section-title-size: 16px;
  --section-title-direction: row;
  --rounded-step-width: 40px;
  --rounded-step-height: 40px;
  --rounded-step-label-size: 16px;
  --steps-width: auto;

  @include for-tablet-up {
    --steps-flex-direction: row;
    --steps-align-items: center;
    --section-title-size: 20px;
    --section-title-direction: column;
    --rounded-step-width: 51px;
    --rounded-step-height: 51px;
    --rounded-step-label-size: 20px;
    --steps-width: 100%;
  }

  display: flex;
  align-items: var(--steps-align-items);
  justify-content: space-between;
  flex-direction: var(--steps-flex-direction);
  max-width: 420px;
  width: var(--steps-width);

  &__section-title {
    font-size: var(--section-title-size);
    color: $dark-blue;
    letter-spacing: 1px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: var(--section-title-direction);
  }

  &__steps {
    display: flex;
    align-items: center;
  }

  &__rounded-step {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--rounded-step-width);
    height: var(--rounded-step-height);
    margin-right: 8px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.35s ease-in-out;
    border: solid 2px #fff;

    &--active {
      border: solid 2px $light-blue;
    }
  }

  &__rounded-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(var(--rounded-step-width) - 10px);
    height: calc(var(--rounded-step-height) - 10px);
    background-color: $dark-blue;
    border-radius: 50%;

    &__label {
      font-size: var(--rounded-step-label-size);
      font-weight: $font-medium;
      color: #fff;
      text-align: center;
      letter-spacing: 1px;
    }
  }

  &__active-number {
    &:after {
      content: ' - ';
    }
    @include for-tablet-up {
      &:after {
        content: '';
      }
    }
  }

  &__active-label {
  }
}

.sticky-price {
  --price-big-size: 24px;
  --currency-month-size: 14px;
  --align-position: flex-end;

  @include for-tablet-up {
    --price-big-size: 36px;
    --currency-month-size: 20px;
    --align-position: center;
  }

  font-size: var(--currency-month-size);
  color: $blue;
  letter-spacing: 1px;
  align-self: var(--align-position);
  white-space: nowrap;

  &__big {
    font-size: var(--price-big-size);
    font-weight: bold;
    letter-spacing: 2px;
    transition: all 0.2s ease-in-out;
    opacity: 1;

    &--animate {
      opacity: 0;
    }
  }
}

.sticky {
  @include for-tablet-up {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
}

.fixed {
  position: fixed;
  bottom: 0;

  z-index: 100;
}
