.cards {
  --grid-column: repeat(auto-fill, 47%);

  @include for-tablet-up {
    --grid-column: repeat(auto-fill, 28%);

    row-gap: 40px;
    column-gap: 40px;
    max-width: 1000px;
    margin: 32px auto 56px;
  }

  display: grid;
  grid-template-columns: var(--grid-column);
  row-gap: 4px;
  column-gap: 4px;
  justify-content: center;
  margin: 16px 0;
  position: relative;
  z-index: 1;
  transition: all 0.2s ease-in-out;

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 40px 0;

    @include for-tablet-up {
      margin: 0 40px;
    }

    &__label {
      display: flex;
      align-items: center;
      white-space: nowrap;
      margin: 0 40px;
      cursor: pointer;

      &__text {
        margin-right: 16px;
        color: $blue;
        letter-spacing: 0.89px;

        @include for-tablet-up {
          font-size: 20px;
          letter-spacing: 1.11px;
        }
      }

      & > svg {
        g {
          stroke: $blue;
        }
      }
    }
  }
}

.card-item {
  --header-height: 120px;
  --header-margin: 32px;
  --smaller-font-size: 12px;
  --bigger-font-size: 16px;
  --price-suffix-label: 10px;

  @include for-tablet-up {
    --header-height: 200px;
    --header-margin: 24px;
    --splash-width: 75px;
    --smaller-font-size: 16px;
    --bigger-font-size: 20px;
    --price-suffix-label: 14px;

    padding: 14px 14px 34px;
    background-image: none;
    border: none;
  }

  width: 100%;
  padding: 4px 4px 10px;
  cursor: pointer;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(153, 163, 175, 0.3)
  );
  border: solid 2px #eaeaea;
  display: flex;
  flex-direction: column;

  &:hover {
    background-image: linear-gradient(to bottom, #fbfbfb, #e9e9e9);
    outline: solid 2px $light-blue;
    box-shadow: 3px 3px 25px 0 rgba(0, 0, 0, 0.19);
  }

  &__header {
    height: var(--header-height);
    display: flex;
    justify-content: center;
    margin-top: var(--header-margin);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__campaign {
    font-size: var(--campaign-font-size);
    color: $light-blue;
  }

  &__name {
    margin-bottom: 7px;
    font-weight: bold;
    color: $blue;
    letter-spacing: $letter-spacing-sm;
    flex-grow: 1;
    font-size: var(--bigger-font-size);
  }

  &__description {
    margin-bottom: 8px;
    font-size: var(--smaller-font-size);
    color: $blue;
    letter-spacing: $letter-spacing-sm;
  }

  &__price-label {
    margin-top: 8px;
    font-size: var(--smaller-font-size);
    color: $blue;
  }

  &__price {
    font-size: var(--price-suffix-label);
    color: $blue;
    letter-spacing: $letter-spacing-sm;

    &--bold {
      display: inline;
      font-size: var(--bigger-font-size);
      font-weight: bold;
      letter-spacing: 1.11px;
    }

    &--strikethrough {
      font-style: italic;
      text-decoration: line-through;
    }
  }
}

// PRODUCT CARD ANIMATION
@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.loading-animation {
  animation: FadeIn 0.35s cubic-bezier(0.34, 1, 0.84, 1) 0.2s;
  position: relative;
  animation-fill-mode: backwards;
}

.loading-animation:nth-child(2n-5) {
  animation-delay: 0.35s;
}
.loading-animation:nth-child(3n-3) {
  animation-delay: 0.35s;
}
