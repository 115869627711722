.footer {
  padding: 42px 8px;
  color: $blue;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 64px;
    padding: 64px 64px;
  }

  &__column {
    padding: 0 16px;
    margin-bottom: 32px;

    &:first-child {
      max-width: 400px;
    }
  }

  &__title {
    margin-bottom: 9px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.78px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.67px;
    color: #62626a;
    font-weight: $font-normal;
  }

  &__link {
    position: relative;
    margin-bottom: 6px;
    line-height: 1.67;

    &:hover {
      & > .footer__link--text {
        &::after {
          left: 0;
          width: 100%;
        }
      }
    }

    &--text {
      position: relative;
      padding-bottom: 4px;

      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 0;
        height: 2px;
        content: '';
        background: $light-blue none repeat scroll 0 0;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
      }
    }
  }

  &__links {
    display: flex;
    flex-direction: column;

    a {
      color: $blue;
    }
  }
}
