.custom-input {
  width: 100%;
  padding: 12px 14px;
  font-size: 14px;
  color: $grey;
  letter-spacing: 1px;
  background-color: #f9f7f7;
  border: solid 1px #979797;
  font-family: $font-primary;

  @media screen and (min-width: 768px) {
    width: 100%;
    padding: 10px 20px;
  }

  &::placeholder {
    color: $grey;
  }

  &--error {
    border: 1px solid rgb(207, 2, 2);
  }
}

.custom-invalid-feedback {
  margin-top: 8px;
}
