.logo {
  @include for-tablet-large-up {
    display: block;
    flex: none;
    align-items: unset;
    justify-content: unset;
  }

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  &__image {
    width: 133px;
    height: 50px;

    @include for-tablet-large-up {
      width: 125px;
      height: 47px;
    }
  }
}

.navigation {
  --nav-link-size: 16px;
  --nav-link-spacing: 16px;

  @include for-tablet-large-up {
    --nav-link-size: 14px;
    --nav-link-spacing: 0;
  }

  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: solid 1px #979797;
  width: 100vw;
  padding: 8px;

  @include for-tablet-large-up {
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.3);
    padding: 0;
  }

  &__hamburger {
    position: absolute;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 16px 64px;
    grid-gap: 16px 40px;
    flex: 1;
  }

  &__item {
    margin-bottom: var(--nav-link-spacing);
    margin-right: 10px;
    margin-left: 10px;
    white-space: nowrap;

    &__link {
      position: relative;
      padding: 8px 0;
      font-size: var(--nav-link-size);
      color: $blue;
      letter-spacing: 0.78px;
      font-weight: $font-medium;

      &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        display: block;
        width: 0;
        height: 2px;
        content: '';
        background: $light-blue none repeat scroll 0 0;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
      }

      &:hover,
      &.active {
        &::after {
          left: 0;
          width: 100%;
          color: currentColor;
        }
      }
    }
  }
}
