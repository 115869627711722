.range-slider {
  &__wrapper {
    width: 100%;
    margin: 29px 0;

    @media screen and (min-width: 768px) {
      width: 295px;
      margin: 12px 10px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;

    &__label {
      color: $blue;
      letter-spacing: 1px;
      padding-left: 8px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
      }
    }

    &__value {
      font-weight: 500;
      color: #6d6d6d;
      letter-spacing: 1px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
      }
    }
  }
}

.input-range {
  &__track {
    height: 1px;
    background: #cccccc;

    &--active {
      background-color: $blue;
    }
  }

  &__slider {
    width: 24px;
    height: 24px;
    background-color: $blue;

    &:active {
      transform: scale(1);
    }
  }
}
