.car-colors {
  --container-justify-position: flex-start;
  --container-padding-bottom: 16px;
  --container-margin-bottom: 24px;
  --inner-flex-wrap: nowrap;
  --color-gap: 8px;

  @include for-tablet-large-up {
    --container-justify-position: center;
    --container-padding-bottom: 0;
    --container-margin-bottom: 48px;
    --inner-flex-wrap: wrap;
    --color-gap: 32px;
  }

  display: flex;
  justify-content: var(--container-justify-position);
  margin-bottom: var(--container-margin-bottom);
  height: 100%;

  &__inner {
    display: flex;
    flex-wrap: var(--inner-flex-wrap);
    grid-gap: var(--color-gap);
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: var(--container-padding-bottom);
    justify-content: var(--container-justify-position);
  }

  &__choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 18px;
    text-align: center;
    cursor: pointer;
    min-width: 17%;

    @include for-tablet-large-up {
      margin: 0;
      flex-basis: 17%;
      min-width: auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin: 4px 0 18px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.35s ease-in-out;

    @media screen and (min-width: 768px) {
      width: 76px;
      height: 76px;
      margin-bottom: 14px;
    }

    &:hover {
      box-shadow: 0 0 0 3px $light-blue;
    }

    &.selected {
      box-shadow: 0 0 0 3px $light-blue;
    }
  }

  &__color-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @media screen and (min-width: 768px) {
      width: 70px;
      height: 70px;
    }
  }

  &__footer {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  &__name {
    font-size: 10px;
    color: $light-grey;
    letter-spacing: 0.78px;
    flex-grow: 1;
    font-weight: $font-medium;

    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }

  &__price {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    white-space: nowrap;

    @media screen and (min-width: 768px) {
      font-size: 16px;
    }
  }
}
