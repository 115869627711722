.resellers {
  max-width: 1202px;
  margin: 0 auto 0;

  &__wrapper {
    max-width: 940px;
    margin: 32px 16px;

    @include for-tablet-large-up {
      margin: 66px auto 32px;
    }
  }

  &__column {
    @media screen and (min-width: 768px) {
      width: 50%;

      &:first-child {
        margin-right: 22px;
      }
    }
  }

  &__row {
    @media screen and (min-width: 768px) {
      display: flex;
    }
  }

  &__step-title {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: $font-medium;
    color: $blue;
    text-align: center;
    letter-spacing: $letter-spacing-md;

    @media screen and (min-width: 768px) {
      margin-bottom: 64px;
      font-size: 40px;
    }
  }

  &__title {
    margin-bottom: 6px;
    font-size: 20px;
    color: $blue;
    letter-spacing: 1px;

    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    font-weight: 500;
    color: #6d6d6d;
    letter-spacing: 1px;

    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }

  &__location {
    width: fit-content;
    color: $light-blue;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      padding: 0 16px;
    }
  }

  &__map {
    height: 212px;
    margin: 16px 0;

    @media screen and (min-width: 768px) {
      height: 268px;
      margin: 0;
    }
  }

  &__search {
    position: relative;
    margin-bottom: 16px;

    &__icon {
      position: absolute;
      top: 19px;
      right: 24px;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        top: 10px;
        right: 24px;
      }
    }
  }
}

.reseller-search-suggestion {
  line-height: 1.69;
  color: $grey;
  letter-spacing: 1px;
  cursor: pointer;

  @include for-tablet-up {
    padding: 0 18px;
    margin-bottom: 4px;
  }

  &:hover {
    background-color: #fafafa;
    border-radius: 4px;
  }

  &__bold-text {
    font-weight: bold;
  }
}

.reseller-item {
  --padding-item: 0;
  --padding-active-item: 16px;

  @media screen and (min-width: 768px) {
    --padding-item: 0 16px;
    --padding-active-item: 32px;
  }

  margin-bottom: 10px;
  padding: var(--padding-item);
  cursor: pointer;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  transition: all 0.2s ease-in-out;
  display: flex;

  &:hover {
    opacity: 0.6;
  }

  &__title {
    margin-bottom: 4px;
    font-size: 18px;
    font-weight: 500;
    color: $dark-blue;
    letter-spacing: 1px;

    & > span {
      font-size: 12px;
      color: #000;
      font-weight: $font-medium;
      float: right;
    }
  }

  &__description {
    color: $grey;
  }

  &--active {
    // padding: var(--padding-active-item);
    position: relative;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' aria-labelledby='title'%0Aaria-describedby='desc' role='img' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ccircle data-name='SuccessCircle' cx='32' cy='32' r='30' transform='rotate(-45 32 32)' fill='none' stroke='%23008200' stroke-miterlimit='10' stroke-width='2' stroke-linejoin='round' stroke-linecap='round'%3E%3C/circle%3E%3Cpath data-name='SuccessPath' fill='none' stroke='%23008200' stroke-miterlimit='10' stroke-width='2' d='M20.998 32.015l8.992 8.992 16.011-16.011' stroke-linejoin='round' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      width: 28px;
      height: 28px;
      position: absolute;
      top: 0;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.reseller-input {
  position: relative;
  margin-bottom: 16px;

  &__search-btn {
    position: absolute;
    top: 10px;
    right: 16px;
    cursor: pointer;
    background: none;
    border: none;
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke: #008200;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  margin-right: 16px;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.5s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
