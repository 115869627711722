@mixin for-small-mobile-up {
  @media screen and (min-width: $small-mobile-up) {
    @content;
  }
}

@mixin for-small-mobile-down {
  @media screen and (max-width: $small-mobile-down) {
    @content;
  }
}

@mixin for-mobile-up {
  @media screen and (min-width: $mobile-up) {
    @content;
  }
}

@mixin for-mobile-down {
  @media screen and (max-width: $mobile-down) {
    @content;
  }
}

@mixin for-tablet-up {
  @media screen and (min-width: $tablet-up) {
    @content;
  }
}

@mixin for-tablet-down {
  @media screen and (max-width: $tablet-down) {
    @content;
  }
}

@mixin for-tablet-large-up {
  @media screen and (min-width: $tablet-large-up) {
    @content;
  }
}

@mixin for-desktop-up {
  @media screen and (min-width: $desktop-up) {
    @content;
  }
}

@mixin for-desktop-down {
  @media screen and (max-width: $desktop-down) {
    @content;
  }
}

@mixin for-desktop-large-up {
  @media screen and (min-width: $desktop-large-up) {
    @content;
  }
}

@mixin for-desktop-large-down {
  @media screen and (max-width: $desktop-large-down) {
    @content;
  }
}

@mixin for-print {
  @media print {
    @content;
  }
}

$small-mobile-down: 374px;
$small-mobile-up: 375px;
$mobile-down: 460px;
$mobile-up: 461px;
$tablet-down: 767px;
$tablet-up: 768px;
$tablet-large-up: 1024px;
$desktop-down: 1199px;
$desktop-up: 1200px;
$desktop-large-down: 1399px;
$desktop-large-up: 1400px;
