.chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 4px 2px 16px;
  margin: 4px;
  cursor: pointer;
  background-color: #9a9999;
  border-radius: 16px;

  @media screen and (min-width: 768px) {
    padding: 4px 4px 4px 20px;
    margin: 12px 10px;
  }

  &__icon {
    margin-right: 4px;
  }
  &:hover {
    .chip__icon {
      background-color: #4d4d4d;
    }
  }

  &__label {
    margin-right: 12px;
    color: #fff;
    letter-spacing: 0.89px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all 0.35s ease-in-out;
  }
}
