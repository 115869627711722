.menu {
  position: absolute;
  top: 73px;
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  padding: 18px;
  background-color: #fff;
  box-shadow: 0 14px 22px 0 rgba(0, 0, 0, 0.3);
  animation: opacity-animate 0.2s linear;

  &__items {
    flex: 1;
  }

  &__item {
    margin-bottom: 18px;

    &__link {
      display: block;

      &:hover,
      &.active {
        & > .menu__item__link--text {
          &::after {
            left: 0;
            width: 100%;
          }
        }
      }

      &--text {
        position: relative;
        padding-bottom: 4px;
        font-size: 20px;
        color: $blue;
        letter-spacing: 1.11px;

        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block;
          width: 0;
          height: 2px;
          content: '';
          background: $light-blue none repeat scroll 0 0;
          transition: width 0.3s ease 0s, left 0.3s ease 0s;
        }
      }
    }
  }

  &__cross {
    height: 22px;
  }
}
