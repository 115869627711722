.radio-button {
  width: 17px;
  height: 17px;
  margin-right: 13px;
  background-color: #d6d6d6;
  border: solid 3px #b6b6b6;
  border-radius: 50%;

  &.checked {
    background-color: $light-blue;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    margin-right: 19px;
    cursor: pointer;
  }

  &__text {
    color: #6d6d6d;
    letter-spacing: 1px;
  }
}
