.car-options {
  --options-wrapper-margin: 24px 16px;

  @include for-tablet-up {
    --options-wrapper-margin: 64px auto;
  }

  max-width: 1202px;
  margin: 0 auto 0;

  &__wrapper {
    max-width: 878px;
    margin: var(--options-wrapper-margin);
  }
}

.car-image {
  --image-margin: 0 auto 32px;
  --image-height: 141px;

  @include for-tablet-up {
    --image-margin: 0 auto 64px;
    --image-height: 325px;
  }

  width: fit-content;
  margin: var(--image-margin);

  &__img {
    height: var(--image-height);
    width: 100%;
    object-fit: contain;
  }
}

.car-contract {
  &__length {
    margin-bottom: 32px;
  }

  &__mileage {
    --mileage-bottom-spacing: 32px;

    @include for-tablet-large-up {
      --mileage-bottom-spacing: 96px;
    }

    margin-bottom: var(--mileage-bottom-spacing);
  }
}

.car-option-title {
  --options-main-heading-size: 24px;
  --options-main-heading-spacing: 40px;

  @include for-tablet-up {
    --options-main-heading-size: 40px;
    --options-main-heading-spacing: 64px;
  }

  margin-bottom: var(--options-main-heading-spacing);
  font-size: var(--options-main-heading-size);
  font-weight: $font-medium;
  color: $blue;
  text-align: center;
  letter-spacing: $letter-spacing-md;
}

.car-option-subtitle {
  --size-option-title: 16px;

  @include for-tablet-large-up {
    --size-option-title: 24px;
  }

  margin-bottom: 6px;
  font-size: var(--size-option-title);
  color: $blue;
  letter-spacing: 1px;
}
