.thanks {
  --height-container: 60vh;
  --title-top-spacing: 0;
  --title-size: 32px;
  --description-size: 16px;

  @include for-tablet-up {
    --height-container: 80vh;
    --title-top-spacing: 64px;
    --title-size: 50px;
    --description-size: 20px;
  }

  text-align: center;
  color: #fff;
  padding: 64px 32px 0 32px;
  max-width: 1000px;
  margin: 0 auto;
  height: var(--height-container);

  &__title {
    margin-top: var(--title-top-spacing);
    color: #fff;
    font-size: var(--title-size);
    font-weight: $font-bold;
  }

  &__description {
    margin: 0;
    font-size: var(--description-size);
    font-weight: $font-normal;
    letter-spacing: $letter-spacing-md;
  }
}
