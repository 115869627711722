.text-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  margin: 32px 0 32px 0;

  @include for-tablet-up {
    max-width: 940px;
    margin: 80px 0 64px 0;
    margin-right: auto;
    margin-left: auto;
  }

  &__title {
    font-size: 28px;
    font-weight: $font-medium;
    color: $blue;
    letter-spacing: 1.56px;
    margin-bottom: 16px;

    @include for-tablet-up {
      font-size: 32px;
      letter-spacing: 1.78px;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-weight: $font-normal;
    line-height: 1.71;
    color: $light-blue;
    letter-spacing: 1px;

    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 2.75;
    }
  }

  &__description {
    margin-bottom: 48px;
  }

  &__list {
    ul {
      color: $blue;
      text-align: left;
      letter-spacing: 0.89px;
      list-style: none;
      list-style-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSIxOHB4IiB2aWV3Qm94PSIwIDAgMjUgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+bGF5ZXIxPC90aXRsZT4KICAgIDxnIGlkPSJTdGFydHBhZ2UiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+CiAgICAgICAgPGcgaWQ9Ik1vYmlsZS1zdGFydHBhZ2UtMS0zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzMuMDAwMDAwLCAtOTI3LjAwMDAwMCkiIHN0cm9rZT0iIzJEOTZDRCIgc3Ryb2tlLXdpZHRoPSIyIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNC4wMDAwMDAsIDkyNi4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJjaGVja0ljb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxwb2x5bGluZSBpZD0ibGF5ZXIxIiBwb2ludHM9IjAgNi45IDguMDUgMTUuMzMzIDIzIDAiPjwvcG9seWxpbmU+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');

      @media screen and (min-width: 768px) {
        font-size: 18px;
        letter-spacing: 1px;
        column-count: 2;
      }

      li {
        padding-left: 8px;
        margin-bottom: 16px;
        font-weight: $font-medium;

        @media screen and (min-width: 768px) {
          padding-left: 20px;
        }
      }
    }
  }
}
