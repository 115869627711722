@font-face {
  font-family: 'Ford Antenna';
  src: url('./FordAntenna-Thin.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Ford Antenna';
  src: url('./FordAntenna-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Ford Antenna';
  src: url('./FordAntenna-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Ford Antenna';
  src: url('./FordAntenna-Semibold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Ford Antenna';
  src: url('./FordAntenna-Bold.ttf');
  font-weight: 700;
}
