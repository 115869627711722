.divider {
  width: 100%;
  border-top-color: $blue;
  border-top-style: solid;
  border-top-width: 1px;

  &.light-blue {
    border-top-color: $light-blue;
  }

  &.grey {
    border-top-color: #979797;
  }

  &.width-2 {
    border-top-width: 2px;
  }
}
