.car-step-section {
  --section-inner-margin: 24px 16px;

  @include for-tablet-large-up {
    --section-inner-margin: 64px auto;
  }

  max-width: 1202px;
  margin: 0px auto;
  border-top: 2px solid $blue;

  &--disabled-border {
    border-top: none;
  }

  &--disabled-marginTop {
    margin-top: 0px !important;
  }

  &--disabled-marginBottom {
    margin-bottom: 0px !important;
  }

  &__inner {
    max-width: 940px;
    margin: var(--section-inner-margin);
    transition: all 0.35s ease-in-out;
    overflow: hidden;

    & h1 {
      --options-main-heading-size: 24px;
      --options-main-heading-spacing: 40px;

      @include for-tablet-up {
        --options-main-heading-size: 40px;
        --options-main-heading-spacing: 64px;
      }

      margin-bottom: var(--options-main-heading-spacing);
      font-size: var(--options-main-heading-size);
      font-weight: $font-medium;
      color: $blue;
      text-align: center;
      letter-spacing: $letter-spacing-md;
    }
  }
}
