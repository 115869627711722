.button {
  --padding-button: 8px 24px;
  --font-size-button: 16px;

  @include for-tablet-large-up {
    --padding-button: 12px 34px;
    --font-size-button: 20px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: var(--padding-button);
  cursor: pointer;
  background-color: #fff;
  border: solid 2px #fff;
  border-radius: 25px;
  box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.5);
  transition: all 0.35s ease-in-out;
  font-weight: 600;
  font-size: var(--font-size-button);
  color: $blue;
  letter-spacing: 1.11px;
  white-space: nowrap;

  &:hover {
    background-color: $blue;
    border-color: $blue;
    color: #fff;

    & .button__text {
      color: #fff;
    }

    & > svg {
      filter: brightness(0) invert(1);
    }
  }

  & > svg {
    margin-left: 16px;
  }

  &--disabled {
    border-color: $light-grey !important;
    pointer-events: none;
    color: $light-grey;
  }

  &--border {
    border-color: $blue;
    box-shadow: none;
  }

  &__text {
    margin-right: 8px;
    color: $blue;
    letter-spacing: 1.11px;
  }
}
