.car-accordion {
  --accordion-body-direction: column;
  --accordion-body-padding: 0;
  --accordion-image-width: 100%;

  @include for-tablet-up {
    --accordion-body-direction: row;
    --accordion-body-padding: 12px 0 16px 36px;
    --accordion-image-width: 298px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    user-select: none;
    border-bottom: 1px solid #979797;
  }

  &__inner {
    display: flex;
    align-items: center;
    cursor: pointer;

    &--isMandatory {
      cursor: not-allowed;
    }

    & > label {
      cursor: pointer;
    }
  }

  &__title {
    margin-left: 8px;
    font-size: 14px;
    color: #6d6d6d;
    letter-spacing: 1px;

    &--bold {
      font-weight: bold;
    }
  }

  &__icon {
    cursor: pointer;
    margin-right: 16px;
  }

  &__body {
    display: flex;
    flex-direction: var(--accordion-body-direction);
    animation: opacity-animate 0.2s linear;

    &--padding {
      padding: var(--accordion-body-padding);
    }
  }

  &__image {
    width: var(--accordion-image-width);
    height: 202px;
    background-color: #c8c8c8;
  }

  &__content {
    flex: 1;
    padding: 24px 32px;
    background-image: linear-gradient(
      to bottom,
      rgba(229, 229, 229, 0.74),
      rgba(248, 248, 248, 0) 57%
    );

    a {
      color: $light-blue;
    }
  }
}
