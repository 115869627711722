.not-found {
  --title-size: 32px;
  --subtitle-size: 18px;

  @include for-tablet-up {
    --title-size: 50px;
    --subtitle-size: 20px;
  }

  text-align: center;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;

  &__title {
    font-size: var(--title-size);
  }

  &__subtitle {
    font-size: var(--subtitle-size);
    margin-top: 0;
    color: $blue;
    font-weight: $font-medium;
  }
}
