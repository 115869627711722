.accordion {
  font-size: 14px;
  line-height: 1.3;
  text-align: left;
  border-bottom: solid 1px #979797;

  &.open {
    background-image: linear-gradient(to bottom, rgba(229, 229, 229, 0.74), rgba(248, 248, 248, 0) 57%);

    .accordion__summary {
      &__title {
        font-weight: bold;
      }

      svg {
        animation: opacity-animate 0.2s linear;
      }
    }
  }

  &__summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 26px;
    cursor: pointer;
    user-select: none;

    @media screen and (min-width: 768px) {
      padding: 15.8px 17px 15.8px 40px;
    }

    &__title {
      max-width: 92%;
      color: #6d6d6d;
      letter-spacing: 0.89px;
    }
  }

  &__details {
    padding: 4px 26px 26px 26px;
    line-height: 1.3rem;
    color: #62626a;
    animation: opacity-animate 0.2s linear;

    @media screen and (min-width: 768px) {
      padding: 0 17px 20px 40px;
    }

    a {
      color: #62626a;
      text-decoration: underline;

      &:hover {
        color: $light-blue;
      }
    }
  }
}
