.image-text {
  max-width: 940px;
  margin: 64px auto;

  &__wrapper {
    padding: 0 32px;

    @media screen and (min-width: 768px) {
      padding: 0 24px;
      margin-top: 16px;
    }
  }

  &__header {
    margin-bottom: 16px;

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }
  }

  &__description {
    margin-bottom: 16px;

    &.center {
      text-align: center;
    }

    &.right {
      text-align: right;
    }

    a {
      color: $light-blue;
    }
  }

  &__button {
    display: flex;

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }

  &__image {
    width: 100%;
    margin-bottom: 60px;
    background-color: #d8d8d8;
    border: solid 1px #979797;
    object-fit: cover;
    height: 389px;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  &__row {
    @media screen and (min-width: 768px) {
      display: flex;
    }

    &.reverse {
      flex-direction: row-reverse;
    }
  }

  &__column {
    @media screen and (min-width: 768px) {
      width: 50%;
    }
  }
}
