.contact-form {
  --flex-width: 100%;
  --container-margin: 64px 16px;

  @include for-tablet-up {
    --flex-width: 47%;
    --container-margin: 64px auto;
  }

  max-width: 758px;
  margin: var(--container-margin);

  &__header {
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    margin: 32px 0;
    padding: 0;

    &--left {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      flex-basis: var(--flex-width);
    }

    &--right {
      flex-basis: var(--flex-width);
      flex-grow: 1;
    }

    textarea {
      width: 100%;
      padding-bottom: 16px;
    }

    label {
      display: none;
    }

    .invalid + input,
    .invalid + textarea {
      border: 1px solid #ee0f0f;
    }
  }

  &__footer {
    .button {
      float: right;
    }
  }
}

.form-errors {
  display: none;
}

.invalid {
  display: none;
}

fieldset {
  border: none;
  padding: 0;
}
