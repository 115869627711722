// .carousel__content.left,
// .slide::before {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   content: '';
//   background-image: linear-gradient(90deg, #101010, transparent);

//   @media screen and (min-width: 500px) {
//     position: initial;
//   }
// }

.carousel {
  background-color: rgb(207, 207, 207);

  &--shadow {
    & .slide {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        background-image: linear-gradient(180deg, #101010, transparent);

        @media screen and (min-width: 500px) {
          position: initial;
        }
      }
    }
  }

  & .slide {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &--large {
    & .slide > img {
      height: 60vh;
      object-fit: cover;
      object-position: 0 76%;

      @include for-tablet-large-up {
        height: 80vh;
      }

      @include for-desktop-large-up {
        height: 70vh;
      }
    }
  }

  &--small {
    & .slide > img {
      height: 40vh;

      @include for-desktop-large-up {
        height: 70vh;
      }
    }
  }

  &__dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 10px 20px;
    cursor: pointer;
    border: solid 2px #cacaca;
    border-radius: 50%;

    @include for-tablet-large-up {
      height: 35px;
      width: 35px;
    }

    &.select {
      background-color: #fff;
      border-color: #fff;
    }
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: 32px 24px;
    color: #fff;
    text-align: right;

    @media screen and (min-width: 376px) {
      width: 70%;
    }

    @media screen and (min-width: 768px) {
      top: 50%;
      right: 8%;
      width: 47%;
      padding: 0;
      transform: translateY(-50%);
    }

    @media screen and (min-width: 992px) {
      width: 35%;
    }

    &.left {
      left: 0;
      align-items: flex-start;
      text-align: left;

      @media screen and (min-width: 768px) {
        right: 0;
        left: 8%;
      }
    }

    &__title {
      margin-right: 6px;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;

      @include for-tablet-large-up {
        margin-right: 0;
        font-size: 32px;
      }

      @media screen and (min-width: 1600px) {
        font-size: 36px;
      }

      &__wrapper {
        align-items: center;
        margin-bottom: 8px;

        @include for-tablet-large-up {
          display: block;
          margin-bottom: 16px;
        }
      }
    }

    &__subtitle {
      font-size: 30px;
      font-weight: 700;
      line-height: 1;

      @include for-tablet-large-up {
        font-size: 54px;
      }

      @media screen and (min-width: 1600px) {
        font-size: 62px;
      }
    }

    &__description {
      margin-bottom: 32px;
      line-height: 1.38;

      @include for-tablet-large-up {
        margin-bottom: 72px;
      }
    }

    &__price {
      margin-bottom: 16px;

      @include for-tablet-large-up {
        margin-bottom: 18px;
        font-size: 21px;
      }

      span {
        font-size: 24px;
        font-weight: 700;

        @include for-tablet-large-up {
          font-size: 36px;
        }
      }
    }
  }
}
