* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: 'Ford Antenna';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background: #fff;
  color: $light-grey;
  margin: 0;
}

.page-wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

@keyframes opacity-animate {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100%;
  }
}

.content-block {
  padding: 16px;

  @media screen and (min-width: 768px) {
    max-width: 940px;
    margin: 0 auto;
  }
}
