.car-information-model {
  --subtitle-size: 14px;

  @include for-tablet-up {
    --subtitle-size: 16px;
  }

  color: $light-blue;
  font-weight: $font-normal;
  font-size: var(--subtitle-size);
  text-align: center;
}

.car-information-equipments {
  --paragraph-margin: 16px 0;
  --title-size: 16px;
  --list-direction: column;

  @include for-tablet-up {
    --paragraph-margin: 32px 0 0 0;
    --title-size: 24px;
    --list-direction: row;
  }

  font-size: 16px;
  color: $light-grey;

  p {
    margin: var(--paragraph-margin);
    text-align: center;
    font-weight: $font-normal;
  }

  & > div {
    max-width: 800px;
    margin: 0 auto;

    h5 {
      color: $blue;
      font-size: var(--title-size);
      margin-bottom: 16px;
      text-align: center;
      font-weight: $font-medium;
    }
    ul {
      font-size: 16px;
      text-align: left;
      font-weight: $font-normal;
      padding-left: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: var(--list-direction);
      grid-gap: 0 16px;

      li {
        flex: 1 1 43%;
        white-space: pre-wrap;
        margin-bottom: 4px;
        list-style: none;
        height: max-content;
        position: relative;

        &::before {
          content: '•';
          position: absolute;
          left: -16px;
        }
      }
    }
  }
}
