// fonts
@import '../../assets/fonts/ford-antenna/style.css';

// base
@import './base/breakpoints';
@import './base/colors';
@import './base/grids';
@import './base/typography';

@import './vendors/bootstrap';
@import './sitecore.scss';

//modules
@import './modules/menu';
@import './modules/carousel';
@import './modules/button';
@import './modules/accordion';
@import './modules/divider';
@import './modules/filter';
@import './modules/multi-select';
@import './modules/range-slider';
@import './modules/chip';
@import './modules/image-text';
@import './modules/text-list';
@import './modules/radio-button';
@import './modules/custom-input';
@import './modules/car-step';
@import './modules/car-accordion';
@import './modules/thanks';
@import './modules/splash-sticker';

//layout
@import './layout/main.scss';
@import './layout/not-found.scss';
@import './layout/navigation.scss';
@import './layout/footer.scss';
@import './layout/sell-process';
@import './layout/faq';
@import './layout/cards';
@import './layout/resellers';
@import './layout/car-options';
@import './layout/car-summary';
@import './layout/car-information';
@import './layout/car-checkout-form';
@import './layout/car-colors';
@import './layout/sticky-header';
@import './layout/forms';
