.fill-order-credentials {
  --justify-button: center;
  --input-group-width: 100%;
  --gdpr-font-size: 14px;
  --container-spacing: 128px;

  @include for-tablet-up {
    --justify-button: start;
    --input-group-width: 47%;
    --gdpr-font-size: 16px;
  }

  @include for-tablet-large-up {
    --container-spacing: 0;
  }

  margin-bottom: var(--container-spacing);
  position: relative;

  &__title {
    color: $blue;
    font-size: 20px;
  }

  &__subtitle {
    color: #6d6d6d;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__input-group {
    flex-basis: var(--input-group-width);
    width: auto;
  }

  &__button-group {
    margin: 32px 0;
    display: flex;
    justify-content: var(--justify-button);
  }

  &__checkbox-group {
    margin-bottom: 8px;

    & > label {
      align-items: flex-start !important;
      cursor: pointer;

      & > span {
        padding-top: 6px;
      }
    }
  }

  &__gdpr-info {
    color: $light-grey;
    font-size: var(--gdpr-font-size);

    a {
      color: $light-blue;
    }
  }
}

.credentials-overlay-disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.7);
  z-index: 99;
}

.overlay-disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.7);
  z-index: 99;

  &--full-screen {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
