.sell-process {
  @include for-tablet-large-up {
    display: flex;
  }

  &.revert {
    @include for-tablet-large-up {
      flex-direction: row-reverse;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include for-tablet-large-up {
      width: 50%;
    }

    &.content {
      padding: 56px 24px;

      @include for-tablet-large-up {
        padding: 0 64px;
      }

      @include for-desktop-large-up {
        padding: 128px;
      }

      ul {
        padding: 0;
        list-style: none;
        counter-reset: counter;

        li {
          display: flex;
          margin-bottom: 20px;
          font-weight: $font-normal;
          line-height: 1.44;
          color: #62626a;
          letter-spacing: 0.89px;
          counter-increment: counter;

          @include for-tablet-large-up {
            margin-bottom: 26px;
          }

          &::before {
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-right: 16px;
            font-size: 17px;
            font-weight: bold;
            line-height: 1.5;
            color: #fff;
            text-align: center;
            content: counter(counter);
            background-color: $light-blue;
            border-radius: 50%;

            @include for-tablet-large-up {
              width: 26px;
              height: 26px;
              margin-right: 18px;
              line-height: 1.6;
            }
          }

          div {
            width: 90%;
          }
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 269px;
    object-fit: cover;

    @include for-tablet-large-up {
      height: 617px;
    }

    @include for-desktop-large-up {
      height: 800px;
    }
  }

  &__title {
    margin-bottom: 16px;

    @include for-tablet-large-up {
      margin-bottom: 32px;
    }
  }
}
