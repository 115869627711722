.faq {
  max-width: 940px;
  margin: 64px auto;

  &__title {
    margin-bottom: 5.7px;
    font-size: 20px;
    color: $blue;
    letter-spacing: 1.11px;

    @media screen and (min-width: 992px) {
      margin-bottom: 18px;
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 1.67px;
    }
  }

  &__description {
    margin-bottom: 40px;
    line-height: 1.44;
    color: $dark-grey;
    letter-spacing: 0.89px;

    @media screen and (min-width: 992px) {
      margin-bottom: 62.4px;
      font-size: 20px;
      line-height: 1.4;
      color: $grey;
      letter-spacing: 1.11px;
    }
  }
}
