.splash-sticker {
  --web-lightblue: #2d96cd;
  --splash-sticker-width: 60px;
  --splash-sticker-height: 60px;
  --splash-sticker-top: 8px;
  --splash-sticker-left: 8px;
  --splash-font-size: 12px;

  @include for-tablet-up {
    --splash-sticker-width: 75px;
    --splash-sticker-height: 75px;
    --splash-sticker-top: 16px;
    --splash-sticker-left: 16px;
    --splash-font-size: 14px;
  }

  position: absolute;
  top: var(--splash-sticker-top);
  left: var(--splash-sticker-left);
  z-index: 1;
  width: var(--splash-sticker-width);
  height: var(--splash-sticker-height);
  color: #fff;
  border-radius: 50%;
  background-image: radial-gradient(
      circle at 84% 16%,
      rgba(255, 255, 255, 0.18),
      rgba(0, 0, 0, 0.23) 83%
    ),
    linear-gradient(to bottom, var(--web-lightblue), var(--web-lightblue));
  display: flex;
  justify-content: center;
  align-items: center;

  &__text {
    margin: 16px;
    overflow-wrap: break-word;
    text-align: center;
    font-size: var(--splash-font-size);
    text-transform: uppercase;
  }
}
