.rmsc {
  &.multi-select {
    --rmsc-main: transparent;

    width: 300px;
    margin: 12px 10px;
  }

  & .dropdown {
    &-container {
      border: solid 1px $dark-blue !important;
      border-radius: 5px !important;

      &:focus-within {
        border-color: $dark-blue;
      }
    }

    &-heading {
      height: auto;
      padding: 12px 28px !important;
      cursor: pointer;
    }

    &-heading-value {
      font-weight: 600;
      letter-spacing: 0.89px;
    }

    &-content {
      z-index: 5;

      ul {
        & > label {
          margin-bottom: 0;
          border-bottom: solid 1px #979797;
        }

        & > li > label {
          margin-bottom: 0;
        }
      }
    }
  }

  & .gray {
    color: $blue;
  }
}

.option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 18px;

  &__text {
    color: $dark-grey;
    letter-spacing: 0.89px;
  }
}
