.car-summary {
  max-width: 1202px;
  margin: 0 auto 0;

  &__wrapper {
    max-width: 940px;
    margin: 24px 16px;

    @include for-tablet-large-up {
      margin: 64px auto;
    }
  }

  &__title {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: $font-medium;
    color: $blue;
    text-align: center;
    letter-spacing: $letter-spacing-md;

    @media screen and (min-width: 768px) {
      margin-bottom: 64px;
      font-size: 40px;
    }
  }

  &__includes {
    letter-spacing: 0.89px;

    ul {
      padding-left: 0;
      list-style: none;
    }

    & li {
      position: relative;
      padding-left: 10px;

      &::before {
        position: absolute;
        top: -24%;
        left: 0;
        font-size: 18px;
        content: '.';
      }
    }
  }

  &__description {
    display: block;
    margin-bottom: 40px;

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      margin-bottom: 24px;
      font-size: 16px;
      color: $blue;
      letter-spacing: 1px;
    }

    &__info {
      margin-bottom: 28px;
    }

    &__image {
      height: 141px;
      margin-bottom: 24px;
      object-fit: contain;
      
      @include for-tablet-large-up {
        max-width: 490px;
        height: auto;
        margin-bottom: 0;
      }
    }

    &__wrapper {
      width: 100%;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 7px 10px 7px;
    font-size: 14px;
    color: $light-grey;
    letter-spacing: 1px;
    border-bottom: 1px solid #979797;

    @media screen and (min-width: 768px) {
      padding: 7px 52px 7px 29px;
      font-size: 16px;
    }

    &__price {
      width: 100%;
      max-width: 115px;
      text-align: right;
    }
  }

  &__box {
    padding: 23px 10px 14px;
    border: solid 1px $light-blue;

    @media screen and (min-width: 768px) {
      padding: 38px 43px 39px 48px;
    }

    &__title {
      padding-left: 10px;
      font-size: 20px;
      color: $blue;
      letter-spacing: 1px;

      @media screen and (min-width: 768px) {
        padding-left: 29px;
        margin-bottom: 17px;
        font-size: 18px;
      }
    }

    &__total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      margin-top: 28px;
      background-color: #efefef;
      flex-wrap: wrap;

      @media screen and (min-width: 768px) {
        padding: 16px 52px 16px 30px;
        margin-top: 18px;
      }

      &__text {
        width: 100%;
        max-width: 115px;
        font-size: 14px;
        font-weight: bold;
        color: #62626a;
        letter-spacing: 1px;
        min-width: 40%;

        @media screen and (min-width: 768px) {
          width: auto;
          max-width: fit-content;
          font-size: 16px;
          line-height: 2;
        }
      }
    }
  }
}

.car-total {
  &__inner {
    padding: 23px 10px 14px;
    border: solid 1px $light-blue;

    @media screen and (min-width: 768px) {
      padding: 40px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    font-size: 14px;
    color: $light-grey;
    letter-spacing: 1px;
    width: 100%;

    @media screen and (min-width: 768px) {
      padding: 8px 32px 8px 32px;
      font-size: 16px;
    }

    &--table {
      border-bottom: 1px solid #979797;
    }
  }

  &__price {
    width: 100%;
    flex-basis: 20%;
    text-align: right;
    white-space: nowrap;

    &--bold {
      font-weight: $font-bold;
    }
  }

  &__name {
    width: 100%;
    font-size: 14px;
    color: #62626a;
    letter-spacing: 1px;
    flex-basis: 80%;

    @media screen and (min-width: 768px) {
      width: auto;
      max-width: fit-content;
      font-size: 16px;
    }

    &--bold {
      font-weight: $font-bold;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-top: 28px;
    background-color: #efefef;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      margin-top: 18px;
      padding: 8px 0;
    }
  }
}
