$font-primary: 'Ford Antenna', sans-serif;
$font-secondary: 'Ford Antenna Cond', sans-serif;

$font-thin: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;

$letter-spacing-sm: 0.89px;
$letter-spacing-md: 1.33px;
$letter-spacing-lg: 1.78px;

h1,
h2,
h3,
h4 {
  color: $blue;
  font-family: $font-primary;
  letter-spacing: $letter-spacing-lg;
  font-weight: $font-medium;
}

h1 {
}

h2 {
  font-size: 28px;

  @include for-tablet-up {
    font-size: 32px;
  }
}

h3 {
  font-size: 24px;

  @include for-tablet-up {
    font-size: 28px;
  }
}

h4 {
  font-size: 16px;

  @include for-tablet-up {
    font-size: 20px;
  }
}

p {
  letter-spacing: $letter-spacing-sm;
  line-height: 1.38;
}

a {
  text-decoration: none;
}
